import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/NavBar";

import Home from "./components/Pages/Home";
import Footer from "./components/footer/Footer";
import JobDetailsPage from "./components/Pages/JobDetailsPage";
import SearchResults from "./components/Pages/SearchResults";
import Login from "./components/login/Login";
import AdminSection from "./components/admin/AdminSection";
import AuthRoute from "./components/AuthRoute";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/job/:job_reference" element={<JobDetailsPage />} />
          <Route path="/search" element={<SearchResults />} />
          <Route
            path="/admin"
            element={<AuthRoute Component={AdminSection} />}
          />
        </Routes>
        <Footer showFooter={false}/>
      </BrowserRouter>
    </div>
  );
}

export default App;
