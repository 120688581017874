import React from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

const HomeJob = ({ job }) => {
  const { job_title, company_name, position, location, expiry_date } = job;

  return (
    <Card className="m-1">
      <CardHeader>
        <CardTitle>{job_title}</CardTitle>
        <CardDescription>{company_name}</CardDescription>
      </CardHeader>
      <CardContent>
        <p>{position}</p>

        <p className="flex align-center">
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            height="1em"
            width="1em"
            className="mt-1 mr-1"
          >
            <path d="M10 20S3 10.87 3 7a7 7 0 1114 0c0 3.87-7 13-7 13zm0-11a2 2 0 100-4 2 2 0 000 4z" />
          </svg>
          {location}
        </p>
      </CardContent>
      <CardFooter>
        <p className="flex align-center">
          <svg
            fill="currentColor"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            className="mt-1 mr-1"
          >
            <path d="M16 8A8 8 0 110 8a8 8 0 0116 0zM8 3.5a.5.5 0 00-1 0V9a.5.5 0 00.252.434l3.5 2a.5.5 0 00.496-.868L8 8.71V3.5z" />
          </svg>
          {expiry_date}
        </p>
      </CardFooter>
    </Card>
  );
};

export default HomeJob;
