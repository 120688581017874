import React, { useEffect, useState } from "react";

import SearchForm from "../search/SearchForm";
import HomeJob from "../job/HomeJob";
import { convertTimestamp, getJobs } from "../../Utils/utils";
import { db } from "../../Utils/firebase";
import { Link } from "react-router-dom";
import JobGridSkeleton from "../Skeleton/JobGridSkeleton";
import Footer from "../footer/Footer";

const Home = () => {
  const handleSearch = (data) => {};
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const gridItems = [1, 2, 3, 4];
  const isProduction = process.env.NODE_ENV === "production";

  useEffect(() => {
    const fetchSearchResults = async () => {
      setIsLoading(true);
      const jobsList = await getJobs(db);
      const sanitizedResults = jobsList.map((job) => {
        return {
          ...job,
          date_posted: convertTimestamp(job.date_posted),
          expiry_date: convertTimestamp(job.expiry_date),
        };
      });
      const filteredJobs = isProduction
        ? sanitizedResults.filter((job) => job.status === "published")
        : sanitizedResults;
      setJobs(filteredJobs);
      setIsLoading(false);
    };

    fetchSearchResults();
  }, [isProduction]);

  return (
    <div>
      <div className="min-h-screen">
        <div className="mx-auto">
          <SearchForm onSearch={handleSearch} />
        </div>

        <div className="container mx-auto md:max-w-[60%] lg:max-w-[60%] p-2">
          <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight m-2 ">
            Similar Jobs
          </h2>
          {isLoading ? (
            <div className="grid md:grid-cols-4 md:gap-4 p-2">
              {gridItems.map((job, index) => (
                <JobGridSkeleton key={index} job={job} />
              ))}
            </div>
          ) : (
            <div className="grid md:grid-cols-4 md:gap-4 p-2">
              {jobs.map((job, index) => (
                <Link to={`/job/${job.job_reference}`}>
                  <HomeJob key={index} job={job} />
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>

      <Footer showFooter={true} />
    </div>
  );
};

export default Home;
