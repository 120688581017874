import React from "react";
import { Link } from "react-router-dom";

const Footer = ({showFooter}) => {
  return showFooter ? (
    <footer className="bg-gray-800 text-white p-4 mt-auto">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 EduCareers. All rights reserved | <Link to="/privacy-policy">Privacy Policy</Link></p>
      </div>
    </footer>) : (<></>)
};

export default Footer;
