import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../Utils/firebase";
import { useNavigate } from "react-router-dom";
import { grantUserRole } from "../../Utils/auth";
import { Button } from "../ui/button";

const GoogleAuthButton = (props) => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const user = await signInWithPopup(auth, provider);
      // grantUserRole(user.uid);
      navigate("/");
    } catch (error) {
      console.error("Google sign-in error:", error.message);
    }
  };

  return (
    <Button variant="outline"
      onClick={handleGoogleSignIn}
      className="py-2 rounded w-full"
    >
      Sign in with Google
    </Button>
  );
};

export default GoogleAuthButton;
