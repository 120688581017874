import React, { useEffect, useState } from "react";
import { auth } from "../../Utils/firebase";
import SignOutButton from "./SignOutButton";

const UserStatus = (props) => {
  const [user, setUser] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevValue) => !prevValue);
  };

  const handleMyJobsClick = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative">
      <div className="flex items-center space-x-4">
        {user ? (
          <div
            className="flex items-center md:space-x-4"
            onClick={toggleDropdown}
          >
            <h2 className="invisible md:visible text-white mr-1">
              Welcome, {user.displayName || user.email}!
            </h2>
            {user.photoURL ? (
              <img
                src={user.photoURL}
                alt={user.displayName || user.email}
                className="rounded-full h-8 w-8 cursor-pointer"
              />
            ) : null}
          </div>
        ) : (
          <div>

          </div>
          // <div className="text-white">
          //   <button className="mr-2">
          //     <Link to={`/login`}>Sign In</Link>
          //   </button>
          // </div>
        )}
      </div>
      {isDropdownOpen && (
        <div className="absolute top-12 right-0 bg-white border border-gray-300 rounded-md shadow-md p-2 space-y-2">
          <div onClick={handleMyJobsClick} className="cursor-pointer">
            <button className="flex mb-2">My Jobs</button>
            <hr />
            <SignOutButton />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserStatus;
