import JobComponent from "../job/Job";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../Utils/firebase";
import { getJobs, convertTimestamp } from "../../Utils/utils";

const SearchResults = () => {
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query") || "";
  const searchLocation = queryParams.get("location") || "";
  const [isLoading, setIsLoading] = useState(false);
  const isProduction = process.env.NODE_ENV === process.env.REACT_APP_PUBLISHED;

  let navigate = useNavigate();

  useEffect(() => {
    const fetchSearchResults = async () => {
      setIsLoading(true);
      if (searchQuery || searchLocation) {
        const jobsCol = collection(db, "jobs");
        const searchQueryLower = searchQuery.toLowerCase();

        const searchResultsQuery = query(
          jobsCol,
          where("job_title", ">=", searchQueryLower),
          where("job_title", "<=", searchQueryLower + "\uf8ff"),
          where("location", "==", searchLocation)
        );

        const resultsSnapshot = await getDocs(searchResultsQuery);
        const resultsList = resultsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            date_posted: convertTimestamp(data.date_posted),
            expiry_date: convertTimestamp(data.expiry_date),
          };
        });
        const filteredJobs = isProduction
          ? resultsList.filter(
              (job) => job.status === process.env.REACT_APP_PUBLISHED
            )
          : resultsList;
        setSearchResults(filteredJobs);
        setIsLoading(false);
      } else {
        setIsLoading(true);
        const jobsList = await getJobs(db);
        const sanitizedResults = jobsList.map((job) => {
          return {
            ...job,
            date_posted: convertTimestamp(job.date_posted),
            expiry_date: convertTimestamp(job.expiry_date),
          };
        });

        const filteredJobs = isProduction
          ? sanitizedResults.filter(
              (job) => job.status === process.env.REACT_APP_PUBLISHED
            )
          : sanitizedResults;
        setSearchResults(filteredJobs);
        setIsLoading(false);
      }
    };

    fetchSearchResults();
  }, [searchQuery, searchLocation, isProduction]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className="md:container">
      <div className="flex mx-auto md:max-w-[60%] lg:max-w-[60%] mt-4 p-4 ">
        <div className="w-full">
          <button
            onClick={handleBackButtonClick}
            className="text-black-500 text-sm cursor-pointer focus:outline-none m-2 flex"
          >
            <svg
              viewBox="0 0 1024 1024"
              fill="currentColor"
              height="1em"
              width="1em"
              className="m-1"
            >
              <path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z" />
            </svg>
            RETURN TO HOME
          </button>
          {isLoading ? (
            <div className="text-center mx-auto">
              <svg
                role="status"
                className="inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : (
            <>
              {searchResults.map((job, index) => (
                <JobComponent key={index} job={job} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
