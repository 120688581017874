import React from "react";
import { auth } from "../../Utils/firebase";
import { signOut } from "firebase/auth";

const SignOutButton = () => {
  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Sign Out Error:", error.message);
    }
  };

  return (
    <button className="text-black" onClick={handleSignOut}>
      Sign Out
    </button>
  );
};

export default SignOutButton;
