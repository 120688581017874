import React from "react";
import { Link } from "react-router-dom";

const JobComponent = ({ job }) => {
  const {
    job_title,
    company_name,
    salary,
    position,
    location,
    date_posted,
    expiry_date,
    job_reference,
  } = job;

  return (
    <div className="bg-white p-4 mb-4 border border-gray-300 rounded">
      <h3 className="font-sans text-3xl font-semibold">{job_title}
      </h3>
      <p className="text-gray-600">{company_name}</p>
      <div className="flex mt-2">
        <div className="md:w-1/3 w-1/2">
          <p>
            <strong>Salary:</strong> {salary}
          </p>
          <p>
            <strong>Date Posted:</strong> {date_posted}
          </p>
        </div>
        <div className="invisible md:visible md:w-1/3">
          <p>
            <strong>Position:</strong> {position}
          </p>

          <p>
            <strong>Expiry Date:</strong> {expiry_date}
          </p>
        </div>
        <div className="md:w-1/3 w-1/2">
          <p>
            <strong>Location:</strong> {location}
          </p>
          <p>
            <strong>Job Reference:</strong> {job_reference}
          </p>
        </div>
      </div>

      <br />
      <button className="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded">
        <Link to={`/job/${job_reference}`}>Show More</Link>
      </button>
    </div>
  );
};

export default JobComponent;
