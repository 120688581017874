import React, { useState } from "react";
import { addJobToFirestore } from "../../Utils/utils";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const JobForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const job_description = jobDescription;
    const job_reference = generateJobReference();
    const status = "draft";
    addJobToFirestore({ ...data, job_reference, status, job_description });
  };

  const generateJobReference = () => {
    const randomNum = Math.floor(Math.random() * 10000);
    return `JOB-${randomNum}`;
  };

  const [jobDescription, setJobDescription] = useState("");

  return (
    <div className="container">
      <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        Add New Job
      </h2>
      <div className="flex mx-auto w-full md:max-w-[60%] lg:max-w-[60%] mt-4 p-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-col bg-white p-8 rounded-md"
        >
          <div className="flex flex-col content-center">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="job_title"
            >
              Job Title:
            </label>
            <input
              id="job_title"
              type="text"
              name="job_title"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              {...register("job_title", { required: true })}
            />
            {errors.job_title && (
              <span className="text-red-600">This field is required</span>
            )}
          </div>

          <div className="flex flex-col content-center">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="company_name"
            >
              Company Name:
            </label>
            <input
              id="company_name"
              type="text"
              name="company_name"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              {...register("company_name", { required: true })}
            />
            {errors.company_name && (
              <span className="text-red-600">This field is required</span>
            )}
          </div>

          <div className="flex flex-col content-center">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="salary"
            >
              Salary:
            </label>
            <input
              id="salary"
              type="text"
              name="salary"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              {...register("salary", { required: true })}
            />
          </div>

          <div className="flex flex-col content-center">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="position"
            >
              Position:
            </label>
            <input
              id="position"
              type="text"
              name="position"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              {...register("position", { required: true })}
            />
          </div>

          <div className="flex flex-col content-center">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="location"
            >
              Location:
            </label>
            <input
              id="location"
              type="text"
              name="location"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              {...register("location", { required: true })}
            />
          </div>

          <div className="flex flex-col content-center">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="expiry_date"
            >
              Expiry Date:
            </label>
            <input
              id="expiry_date"
              type="date"
              name="expiry_date"
              {...register("expiry_date", { required: true })}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
            />
          </div>

          <div className="flex flex-col content-center">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="apply_link"
            >
              Job Apply Link:
            </label>
            <input
              type="text"
              name="apply_link"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              {...register("apply_link", { required: false })}
            />
          </div>

          <div className="flex flex-col content-center">
            <label
              className="block text-sm font-medium text-gray-600"
              htmlFor="job_description"
            >
              Job Description:
            </label>
            <ReactQuill
              theme="snow"
              className="mb-16"
              value={jobDescription}
              onChange={setJobDescription}
            />
          </div>

          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 mt-2 rounded"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default JobForm;
