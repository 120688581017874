import React from "react";
import { Link } from "react-router-dom";
import UserStatus from "../login/UserStatus";
import logo from '../../images/logo.png';

const Navbar = () => {

  return (
    <div className="p-4 bg-blue-400 sticky top-0">
      <div>
        <div className="mx-auto md:max-w-[60%] lg:max-w-[60%]">
          <div className="flex justify-between items-center">
            <div className="text-white text-4xl font-bold">
              <Link to="/">
                <img src={logo} alt="Logo" className="md:h-8 lg:h-8 h-6" />
              </Link>
            </div>

            <UserStatus />
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
