import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDGaOXh8ciFIg04m4bjtpMK02z7r6PVVG8",
  authDomain: "educareersza.firebaseapp.com",
  projectId: "educareersza",
  storageBucket: "educareersza.appspot.com",
  messagingSenderId: "620871810616",
  appId: "1:620871810616:web:6694ff144021968ee1b0cd",
  measurementId: "G-C4W3ZDKF01",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
