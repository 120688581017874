import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchForm = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [location, setLocation] = useState("");
  let navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    const queryString = `?query=${encodeURIComponent(
      searchQuery
    )}&location=${encodeURIComponent(location)}`;
    navigate(`/search${queryString}`);
  };

  return (
    <div
      style={{
        backgroundImage:
          'url("https://images.unsplash.com/photo-1546410531-bb4caa6b424d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZWR1Y2F0b3J8ZW58MHx8MHx8fDA%3D")',
      }}
    >
      <div className="mx-auto  md:max-w-[60%]">
        <div className="flex items-start justify-start  h-full p-4">
          <form
            className="bg-white p-8 rounded-md w-full md:w-4/12 lg:w-4/12"
            onSubmit={handleSearch}
          >
            <h2 className="text-2xl font-semibold mb-6">Search Jobs</h2>
            <div className="mb-4">
              <label
                htmlFor="job-title"
                className="block text-sm font-medium text-gray-600"
              >
                Job Title, Skill, School, or Keyword
              </label>
              <input
                type="text"
                id="job-title"
                name="job-title"
                placeholder="Enter keywords..."
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                value={searchQuery}
                disabled
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-600"
              >
                Location
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 p-2 border border-gray-300 rounded w-full"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                <option value="">Select Location</option>
                <option value="Durban">Durban</option>
                <option value="Cape Town">Cape Town</option>
              </select>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded w-full"
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
