import { db } from "./firebase";

import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";

export const getJobs = async (db) => {
  const jobsCol = collection(db, "jobs");
  const jobsSnapshot = await getDocs(jobsCol);
  const jobsList = jobsSnapshot.docs.map((doc) => doc.data());
  return jobsList;
};

export async function getJobByReference(db, jobReference) {
  const jobsCol = collection(db, "jobs");
  const jobQuery = query(jobsCol, where("job_reference", "==", jobReference));

  const jobSnapshot = await getDocs(jobQuery);

  if (jobSnapshot.empty) {
    return null;
  } else {
    const jobData = jobSnapshot.docs[0].data();
    return jobData;
  }
}

export const addJobToFirestore = async (jobData) => {
  try {
    const jobDocRef = collection(db, "jobs");
    const jobWithTimestamps = {
      ...jobData,
      date_posted: serverTimestamp(),
      expiry_date: Timestamp.fromDate(new Date(jobData.expiry_date)),
    };

    await addDoc(jobDocRef, jobWithTimestamps);
  } catch (error) {
    console.error("Error adding job:", error);
  }
};

export const convertTimestamp = (timestamp) => {
  let date = timestamp.toDate();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  let yyyy = date.getFullYear();

  date = yyyy + "-" + mm + "-" + dd;
  return date;
};
