import React from "react";
import { Skeleton } from "../ui/skeleton";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

const JobGridSkeleton = () => {
  return (
    <Card className="m-1">
      <CardHeader>
        <CardTitle>
          <Skeleton className="h-4" />
        </CardTitle>
        <CardDescription>
          <Skeleton className="h-4" />
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Skeleton className="h-4 flex" />

      </CardContent>
      <CardFooter>
        <Skeleton className="h-4" />
      </CardFooter>
    </Card>
  );
};

export default JobGridSkeleton;
