import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

export const grantAdminRole = async (userUid) => {
  const roleDocRef = doc(db, "roles", userUid);

  const roleDoc = await getDoc(roleDocRef);

  if (roleDoc.exists()) {
    await setDoc(roleDocRef, { isAdmin: true }, { merge: true });
  } else {
    await setDoc(roleDocRef, { isAdmin: true });
  }
};

export const checkAdminRole = async (user) => {
  if (user) {
    const roleDocRef = doc(db, "roles", user.uid);
    const roleDoc = await getDoc(roleDocRef);

    if (roleDoc.exists() && roleDoc.data().isAdmin) {
      return true;
    }

    return false;
  }
};

// export const grantUserRole = async (userUid) => {
//   const roleDocRef = doc(db, "roles", userUid);

//   const roleDoc = await getDoc(roleDocRef);

//   if (roleDoc.exists()) {
//     await setDoc(roleDocRef, { isAdmin: false }, { merge: false });
//   } else {
//     await setDoc(roleDocRef, { isAdmin: false });
//   }
// };
